:root {
  --color-neutral-600: #2f2f40;
  --color-primary-400: #ff4171;
  --color-primary-500: #f02458;
  --color-secondary-400: #00f5bc;
  --color-secondary-500: #00e6ad;
  --color-secondary-600: #048c6c;
  --color-neutral-100: #fff;
  --color-neutral-200: #e6ecf8;
  --color-neutral-400: #424259;
  --ff-primary: "europa", sans-serif;
  --ff-secondary: "Roboto", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

::-webkit-scrollbar {
  display: none;
}

.main {
  font-family: var(--ff-primary);
  color: var(--color-neutral-600);
  font-size: 1.125rem;
  padding: 0;
}

.container {
  width: 90%;
  max-width: 70.5rem;
}

@media only screen and (max-width: 62em) {
  .container {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
  }
}

.btn-primary {
  background: var(--color-secondary-400);
  color: var(--color-neutral-400) !important;
  border-color: var(--color-secondary-400) !important;
  padding: 0.5rem 1rem;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: var(--color-secondary-500) !important;
  border-color: var(--color-secondary-500) !important;
}

.btn-primary:disabled {
  background: var(--color-secondary-600) !important;
  border-color: var(--color-secondary-600) !important;
}

header {
  text-align: center;
  padding: 7.5rem 3rem;
  justify-content: center;
  display: flex;
}

header h1 {
  font-family: var(--ff-secondary);
  margin-bottom: 2rem;
}

header p,
.project-header p {
  font-size: 1.375rem;
  font-weight: 300;
  margin: 0;
}

.project-header {
  text-align: center;
  padding: 3.75rem 3rem;
}

.primary-section p {
  font-weight: 300;
  font-size: 1.375rem;
}

.primary-section p:last-child {
  margin-bottom: 6.25rem;
}

.primary-section {
  background-color: var(--color-neutral-400);
  color: var(--color-neutral-100);
  text-align: center;
  padding: 7.5rem 2rem;
  padding-bottom: 12rem;
  justify-content: center;
  display: flex;
}

.primary-section div,
header div {
  margin: 0 auto;
}

.primary-section .row {
  justify-content: center;
}

@media only screen and (max-width: 62em) {
  .primary-section {
    /* margin-bottom: 3rem; */
    padding-top: 4rem;
  }

  .primary-section p {
    margin-bottom: 3rem;
    font-size: 1rem;
  }

  .primary-section h2 {
    font-size: 1.43rem;
  }
}

.skills-section {
  padding: 1rem;
}

.skill-card {
  text-align: center;
  padding: 4.375rem 2.813rem;
}

.skill-card:not(:last-child) {
  border-right: 1px solid var(--color-neutral-200);
}

.skill-card h2 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.skill-card p {
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.skill-card p.lg {
  height: 5rem;
}

.skill-card p.sm {
  height: 3.75rem;
}

.skill-card h3 {
  font-size: 1.125rem;
  margin-bottom: 2rem;
}

.skill-card ul {
  list-style: none;
  padding: 0;
}

.skill-card ul li {
  font-weight: 300;
  margin-bottom: 0.25rem;
}

@media only screen and (max-width: 62em) {
  .skill-card {
    padding: 2rem 1.5rem;
    /* min-width: 300px !important; */
    /* max-width: 343px !important; */
    border-right: none !important;
  }

  .skill-card:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-200) !important;
  }
}

.box-shadow {
  margin-top: -12rem;
  background-color: var(--color-neutral-100);
  border-radius: 0.75rem;
  box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%),
    0 0 0 1px var(--color-neutral-200);
}

.skill-card svg {
  color: var(--color-primary-400);
  font-size: 3.75rem;
  margin-bottom: 2rem;
}

.recent-work-section {
  text-align: center;
  padding: 7.5rem 1rem;
  border-bottom: 1px solid var(--color-neutral-200);
}

.work-tile {
  height: 15.625rem;
  border-radius: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.work-tile.midchains {
  background-color: var(--color-neutral-400);
}

footer {
  bottom: 0;
  width: 100%;
  padding: 4rem;
  text-align: center;
  background-color: var(--color-neutral-400);
  color: var(--color-neutral-100);
}

footer img {
  width: 6.25rem;
  height: 6.25rem;
  margin-bottom: 2rem;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.footer-link {
  color: var(--color-secondary-400);
  text-decoration: underline;
  cursor: pointer;
}

.footer-link:hover {
  color: var(--color-secondary-500);
}

.footer-link:not(:last-child) {
  margin-right: 1rem;
}

footer p {
  font-weight: 300;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer p svg {
  margin: 0 4px;
  font-size: 1.125rem;
}

footer h4 {
  font-weight: 300;
  margin-bottom: 1rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: var(--color-neutral-400);
}

.navbar .navbar-brand {
  padding: 0;
  margin: 0;
}

.navbar .navbar-brand img {
  height: 6.25rem;
  width: 6.25rem;
}

.navbar a,
.project-section a {
  color: var(--color-neutral-600);
  text-decoration: none;
  margin-right: 0.5rem;
}

.navbar a:hover {
  color: var(--color-neutral-600);
}

@media only screen and (max-width: 62em) {
  .navbar .navbar-brand img {
    height: 5rem;
    width: 5rem;
  }

  .navbar {
    padding: 0.5rem 1rem;
  }
}

.project-section {
  padding: 3rem;
  margin-bottom: 1rem;
}

.project-row {
  margin-bottom: 1rem;
  justify-content: center;
  display: flex;
}

.project-section .col-md-4 {
  justify-content: center;
  display: flex;
}

.project-card {
  min-height: 18.75rem;
  border-radius: 13px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.project-card:hover {
  box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%),
    0 0 0 1px var(--color-neutral-200);
}

.project-card h3 {
  font-size: 1.125rem;
  color: var(--color-primary-400);
}

.project-card p {
  font-weight: 300;
  font-size: 1rem;
}

.project-card img {
  height: 5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 62em) {
  .project-section {
    padding: 1rem;
  }

  .project-header {
    padding: 1rem;
    padding-top: 2rem;
  }
}

.form-control {
  border: none;
  background: #f0f0f0 !important;
  margin-bottom: 0.5rem;
  min-height: 3.125rem;
  color: var(--color-neutral-600);
}
